<div *ngIf="isLoading">
  <div class="intestazione-container">
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
  </div>
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>
    </div>
  </div>
</div>

<div *ngIf="!isLoading" class="calendar-component">
  <div class="body-top-container">
    
    <div class="intestazione-container">
      <div *ngIf="canGoBack" class="back-btn-container">
        <button class="btn-summary" (click)="goBack()">
          <div class="d-none d-md-block"><mat-icon>chevron_left</mat-icon>indietro</div>
          <div class="d-md-none"><mat-icon>chevron_left</mat-icon></div>
        </button>
      </div>
      <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
    </div>

    <div class="progress-container">
      <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
    </div>

    <div class="title-container">
      <h2>Scegli data ed ora</h2>
    </div>

    <div class="row" style="width: 100%;padding:0em 3em;">
      <div class="col-xs-4 col-sm-6 col-12">
        <div class="calendar-container">
          <mat-calendar
            [startAt]="selectedDate"
            [(selected)]="selectedDate"
            [minDate]="minDate"
            [maxDate]="maxDate"
            (selectedChange)="handleDateSelection()">
          </mat-calendar>
        </div>
      </div>
      <div class="col-xs-8 col-sm-6 col-12 accordion-container">
        <div class="time-container">
          <div style="margin-top: 2em;">
            <mat-accordion>

              <mat-expansion-panel [disabled]="orari_mattino_disabled" [expanded]="isMattinoExpanded">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Mattina
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <button
                  class="time-button"
                  *ngFor="let slot of orari_mattino"
                  (click)="handleTimeSelection(slot)" 
                  [ngClass]="[!slot.available? 'time-btn-disabled' : '', slot.selected? 'isSelected': '']"
                  [disabled]="!slot.available"
                >
                  {{ slot.start_time }} - {{ slot.end_time }}
                  <br>
                  <br>
                  {{ slot.label }}
                </button>
              </mat-expansion-panel>

              <mat-expansion-panel [disabled]="orari_pomeriggio_disabled" [expanded]="isPomeriggioExpanded">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Pomeriggio
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <button
                  class="time-button"
                  *ngFor="let slot of orari_pomeriggio"
                  (click)="handleTimeSelection(slot)" 
                  [ngClass]="[!slot.available? 'time-btn-disabled' : '', slot.selected? 'isSelected': '']"
                  [disabled]="!slot.available"
                >
                  {{ slot.start_time }} - {{ slot.end_time }}
                  <br>
                  <br>
                  {{ slot.label }}
                </button>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
    <div class="submit-btn">
      <button [ngClass]="isComplete? 'btn-submit' : 'btn-disabled'" (click)="goToSummary()" [disabled]="!isComplete" style="margin-bottom: 20px;">Procedi</button>
    </div>
</div>
