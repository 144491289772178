import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/service/booking.service';

@Component({
  selector: 'app-delete-booking',
  templateUrl: './delete-booking.component.html',
  styleUrls: ['./delete-booking.component.scss'],
})
export class DeleteBookingComponent implements OnInit {
  subscription: any;
  textValue: string;
  idReservation: string;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private bookingService: BookingService
  ) {}

  ngOnInit() {
    this.router.params.subscribe((params) => {
      //console.log('params', params);
      if (params) {
        this.idReservation = params.id;
      }
    });
  }

  deleteBooking() {
    this.bookingService.deleteReservation(this.idReservation).subscribe();
    this.route.navigate(['/cancel-complete']);
  }

  goBack(idReservation: string) {
    this.route.navigate([`/edit/${idReservation}`, { id: this.idReservation }]);
  }
}
