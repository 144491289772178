<div *ngIf="isLoading">
  <div class="intestazione-container">
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
  </div>
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>


    </div>
  </div>
</div>

<div *ngIf="!isLoading">

  <div class="intestazione-container">
    <div class="back-btn-container">
      <button class="btn-summary" (click)="goBack()">
        <div class="d-none d-md-block"><mat-icon>chevron_left</mat-icon>indietro</div>
        <div class="d-md-none"><mat-icon>chevron_left</mat-icon></div>
      </button>
    </div>
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg" />
  </div>
  
  <div class="progress-container">
    <mat-progress-bar mode="determinate" value="75"></mat-progress-bar>
  </div>
  <div class="title-container">
    <h2>Riepilogo Prenotazione</h2>
  </div>
  
  <div class="row">
    <div class="recap">
      <p>
        Per poter confermare la tua prenotazione devi compilare i seguenti campi:
      </p>
    </div>
  </div>

  <div class="row">
    <form [formGroup]="form">
      <div class="summary-container">
        <mat-list>
          <div>Evento</div>
          <div class="direct">{{ event_name }}</div>
          
          <mat-divider></mat-divider>

          <div>Data e Ora</div>
          <div class="direct">{{ event_date_time }}</div>
          
          <mat-divider></mat-divider>
          
          <ng-container *ngIf="findUserField('user_name') as user_name_field">
            <div>{{user_name_field.user_field_label}}</div>
            <input type="text" class="form-control" formControlName="name" maxlength="150" />
            <mat-error *ngIf="form.get('name')?.hasError('required') &&
                          form.get('name')?.touched">
              {{ "Il nome è obbligatorio" }}
            </mat-error>
            <mat-error *ngIf="form.get('name')?.hasError('forbiddenName') &&
                          form.get('name')?.touched">
              {{ "Il nome non è valido" }}
            </mat-error>
            
            <mat-divider></mat-divider>
          </ng-container>
          
          <ng-container *ngIf="findUserField('user_last_name') as user_last_name_field">
            <div>{{user_last_name_field.user_field_label}}</div>
            <input type="text" class="form-control" formControlName="surname" maxlength="150" />
            <mat-error *ngIf="form.get('surname')?.hasError('required') &&
                          form.get('surname')?.touched">
              {{ "Il cognome è obbligatorio" }}
            </mat-error>
            <mat-error *ngIf="form.get('surname')?.hasError('forbiddenName') &&
                          form.get('surname')?.touched">
              {{ "Il cognome non è valido" }}
            </mat-error>
            
            <mat-divider></mat-divider>
          </ng-container>

          <ng-container *ngIf="findUserField('user_phone') as user_phone_field">
            <div>{{user_phone_field.user_field_label}}</div>
            <input type="text" class="form-control" formControlName="phoneNumber" maxlength="10" />
            <mat-error *ngIf="form.get('phoneNumber')?.hasError('required') &&
                          form.get('phoneNumber')?.touched">
              {{ "Il numero di telefono è obbligatorio" }}
            </mat-error>
            <mat-error *ngIf="form.get('phoneNumber')?.hasError('pattern') &&
                          form.get('phoneNumber')?.touched">
              {{ "Il numero di telefono non è corretto" }}
            </mat-error>

            <mat-divider></mat-divider>
          </ng-container>

          <ng-container *ngIf="findUserField('user_fiscal_code') as user_fiscal_code_field">
            <div>{{user_fiscal_code_field.user_field_label}}</div>
            <input type="text" class="form-control" formControlName="fiscalCode" maxlength="16" (input)="form.get('fiscalCode')?.setValue(form.get('fiscalCode')?.value.toUpperCase())"/>
            <mat-error *ngIf="form.get('fiscalCode')?.hasError('required') &&
                          form.get('fiscalCode')?.touched">
              {{ "Il codice fiscale è obbligatorio" }}
            </mat-error>
            <mat-error *ngIf="form.get('fiscalCode')?.hasError('pattern') &&
                          form.get('fiscalCode')?.touched">
              {{ "Il codice fiscale non è corretto" }}
            </mat-error>

            <mat-divider></mat-divider>
          </ng-container>

          <ng-container *ngIf="findUserField('user_email') as user_email_field">
            <div>{{user_email_field.user_field_label}}</div>
            <input class="form-control" type="email" formControlName="email" maxlength="150"/>
            <mat-error *ngIf="
                form.get('email')?.hasError('required') &&
                form.get('email')?.touched
              ">{{ "L'email è obbligatoria" }}</mat-error>
            <mat-error *ngIf="form.get('email')?.hasError('email') &&
              form.get('email')?.touched">
              {{"L'email non è valida"}}</mat-error>
            
            <mat-divider></mat-divider>
            
            <div>Ripeti {{user_email_field.user_field_label}}</div>
            <input class="form-control" type="email" formControlName="email_2" onpaste="return false" ondrop="return false" />
            <mat-error *ngIf="form.get('email_2')?.touched && form.get('email')?.touched
                            && checkEmailFields()">
              {{"Le due email non coincidono"}}
            </mat-error>
            <mat-error *ngIf="form.get('email_2')?.hasError('email_2') &&
              form.get('email_2')?.touched">
              {{"L'email non è valida"}}</mat-error>
            
            <mat-divider></mat-divider>
          </ng-container>

          <ng-container *ngIf="findUserField('number_of_users') as number_of_users_field">
            <div>{{number_of_users_field.user_field_label}} <b *ngIf="event_max_reservations_per_user">{{ max_reservations_message }}</b></div>
            <input type="number" class="form-control" formControlName="users" min="1" [max]="form_max_reservations_per_user"/>
            
            <mat-divider></mat-divider>
          </ng-container>

          <ng-container *ngIf="findUserField('user_guest') as user_guest_field">
            <div>{{user_guest_field.user_field_label}}</div>
            <button mat-stroked-button class="add-btn" (click)="addGuest()" [disabled]="user_guests && user_guests.length >= 2">
              <mat-icon>add</mat-icon>
              Aggiungi
            </button>

            <div *ngFor="let guest of user_guests; let i = index;" style="margin-left: 20px;">
              <div>Dati Accompagnatore</div>
              <div style="display: flex; margin-bottom: 10px;">
                <input type="text" class="form-control user-guest" maxlength="150" [(ngModel)]="user_guests[i].name" name="user_guest_{{i}}" [ngModelOptions]="{standalone: true}"/>
                <button mat-mini-fab style="background-color: transparent; margin-left: 10px;" (click)="deleteGuest(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>

            <mat-divider></mat-divider>
          </ng-container>
        </mat-list>
      </div>


      <div class="term-conditions">
        <mat-checkbox class="termini" formControlName="terminiChecked">Dichiaro di aver letto e compreso l'<a
            href="https://www.credem.it/content/dam/credem/documenti/Documentazione/Informativa%20privacy%20prenotazione%20eventi%202022.pdf"
            target="_blank">informativa privacy</a>
        </mat-checkbox>
      </div>

      <div class="submit-btn">
        <button [ngClass]="form.valid && !checkFormInvalid() ? 'btn-submit' : 'btn-disabled'" [disabled]="form.invalid || checkFormInvalid()"
          (click)="onSubmit()">
          Conferma Appuntamento
        </button>
      </div>

    </form>
  </div>
</div>