import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-completed',
  templateUrl: './delete-completed.component.html',
  styleUrls: ['./delete-completed.component.scss']
})
export class DeleteCompletedComponent implements OnInit {

  isSuccess: boolean = true;

  constructor(
    private route: Router,
  ) { }

  ngOnInit() {
  }

  get successImage(){
    if(this.isSuccess){
      return "../../../assets/img/check.png"
    } else {
      return "../../../assets/img/remove.png"  
    }
  }

  close(){
   //console.log("close")
  }

  restart(){
    this.route.navigate([""]);
  }

}
