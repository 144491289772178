import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BookingService } from "src/app/service/booking.service";
import { finalize } from "rxjs/operators";
import { HomeService } from "src/app/service/home.service";
import { ExistingReservation, Guest, Reservation } from "src/app/model/reservation.model";
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Event, UserField } from "src/app/model/event.model";

// TODO recuperare da DB, colonna regex
let PHONE_PATTERN = /^[\d]{9,10}$/;
let FISCAL_CODE_PATTERN = /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;

@Component({
  selector: "app-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit {
  isLoading: boolean = true;

  private reservationModel: Reservation = new Reservation();
  event_name: string;
  event_date_time: string;
  event_max_reservations_per_user: number | undefined = undefined;
  max_reservations_message: string;
  form_max_reservations_per_user: number = 100;

  private user_fields: Array<UserField> = new Array<UserField>();
  user_guests: Array<Guest> = new Array<Guest>();

  private old_reservation: ExistingReservation;
  private new_reservation_id: string;

  form: FormGroup = this.fb.group({
    name: ["", [forbiddenNameValidator]],
    surname: ["", [forbiddenNameValidator]],
    email: ["", [Validators.email, Validators.required]],
    email_2: ["", [Validators.email, Validators.required]],
    phoneNumber: ["", [Validators.pattern(PHONE_PATTERN), Validators.required]],
    fiscalCode: ["", [Validators.pattern(FISCAL_CODE_PATTERN), Validators.required]],
    users: [1],
    terminiChecked: [false],
  });

  constructor(
    private route: Router,
    private bookingService: BookingService,
    private fb: FormBuilder,
    private homeService: HomeService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    let res = this.homeService.getEventObject();
    if(res) {
      this.reservationModel.uuid_event = res.uuid_event;
      this.event_name = res.event_name;
      this.user_fields = res.user_fields;

      this.handelMaxReservationsPerUser(res);

      this.handleUserFields();
    }

    let res2 = this.homeService.getEditReservationObject();
    if(res2) {
      this.old_reservation = res2;

      // Pre-fill the user fields
      this.populateFields();
    }

    let res3 = this.homeService.getSlotObject();
    if(res3) {
      this.reservationModel.id_event_slot = res3.id_event_slot;
      this.event_date_time = res3.slot_date_time;
    }

    this.isLoading = false;
  }

  private handelMaxReservationsPerUser(event: Event) {
    if (event.event_max_reservations_per_user && event.event_max_reservations_per_user != 9999) {
      this.event_max_reservations_per_user = event.event_max_reservations_per_user;

      this.max_reservations_message = this.event_max_reservations_per_user == 1 ? "(Massimo una persona)" : `(Massimo ${this.event_max_reservations_per_user} persone)`
      this.form.get("users")?.setValidators([Validators.min(1), Validators.max(this.event_max_reservations_per_user), Validators.required]);
      this.form_max_reservations_per_user = this.event_max_reservations_per_user;
    } else {
      this.form.get("users")?.setValidators([Validators.min(1), Validators.max(100), Validators.required]);
      this.form_max_reservations_per_user = 100;
    }

    this.form.get("users")?.updateValueAndValidity();
  }

  // Remove validators on not existing fields for this event 
  private handleUserFields() {
    if (this.user_fields) {
      if (!this.user_fields.find(field => field.user_field_reference_name == 'user_phone')) {
        this.form.get("phoneNumber")?.clearValidators();
        this.form.get("phoneNumber")?.updateValueAndValidity();
      }

      if (!this.user_fields.find(field => field.user_field_reference_name == 'user_fiscal_code')) {
        this.form.get("fiscalCode")?.clearValidators();
        this.form.get("fiscalCode")?.updateValueAndValidity();
      }
    }
  }

  private populateFields() {
    this.form.setValue({
      name: this.old_reservation.name,
      surname: this.old_reservation.surname,
      email: this.old_reservation.email,
      email_2: this.old_reservation.email,
      phoneNumber: this.old_reservation.phone,
      fiscalCode: this.old_reservation.fiscal_code,
      users: this.old_reservation.number_of_users,
      terminiChecked: false
    });

    if (this.old_reservation.user_guest) this.user_guests = this.old_reservation.user_guest;
  }

  goBack() {
    this.route.navigate(["date-picking"]);
  }

  onSubmit() {
    this.isLoading = true;

    this.reservationModel.users[0].user_name = this.form.get("name")?.value;
    this.reservationModel.users[0].user_last_name = this.form.get("surname")?.value;
    this.reservationModel.users[0].user_email = this.form.get("email")?.value;

    if (this.user_fields && this.user_fields.find(field => field.user_field_reference_name == 'user_phone')) {
      this.reservationModel.users[0].user_phone = this.form.get("phoneNumber")?.value;
    }

    if (this.user_fields && this.user_fields.find(field => field.user_field_reference_name == 'user_fiscal_code')) {
      this.reservationModel.users[0].user_fiscal_code = this.form.get("fiscalCode")?.value;
    }

    if (this.user_fields && this.user_fields.find(field => field.user_field_reference_name == 'number_of_users')) {
      this.reservationModel.number_of_users = this.form.get("users")?.value;
    }

    if (this.user_fields && this.user_fields.find(field => field.user_field_reference_name == 'user_guest')) {
      this.reservationModel.user_guest = this.user_guests;
    }

    this.recaptchaV3Service.execute('submit').subscribe(
      (token: string) => {
        this.reservationModel.recaptcha_token = token;

        if (this.old_reservation) {
          this.bookingService.editReservation(this.reservationModel, this.old_reservation.id_reservation).pipe(
            finalize(() => this.goToComplete(this.new_reservation_id))
          ).subscribe(
            res => {
              if (res) {
                this.new_reservation_id = res.uuid;
              }
            },
            err => {
              if (err.error.type == "handled") { this.homeService.setErrorMessage(err.error.message); }
            }
          );
        } else {
          this.bookingService.createReservation(this.reservationModel).pipe(
            finalize(() => this.goToComplete(this.new_reservation_id))
          ).subscribe(
            res => {
              if (res) {
                this.new_reservation_id = res.uuid;
              }
            },
            err => {
              if (err.error.type == "handled") { this.homeService.setErrorMessage(err.error.message); }
            }
          );
        }
      },
      err => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }

  private goToComplete(idReservation: string) {
    this.isLoading = false;
    this.route.navigate(["complete", { idReservation }]);
  }

  findUserField(reference_name: string) {
    return this.user_fields.find(el => {return el.user_field_reference_name == reference_name});
  }

  addGuest() {
    this.user_guests.push(new Guest());
  }

  deleteGuest(index: number) {
    this.user_guests.splice(index, 1);
  }

  // Return True if invalid
  checkEmailFields() {
    return (!this.form.get('email_2')?.hasError('email') && this.form.get("email")?.value != this.form.get("email_2")?.value);
  }

  // Return True if invalid
  /*
  checkPhoneField() {
    const numberFormat = /^[\d]{9,10}$/;
  
    let phone: string = this.form.get('phoneNumber')?.value;
  
    return phone.length != 0 && !phone.match(numberFormat);
  }*/

  // Return True if invalid
  /*
  checkFiscalCodeField() {
    // Trovata su internet
    const fiscalCodeFormat = 

    let fiscalCode: string = this.form.get('fiscalCode')?.value;

    return fiscalCode.length != 0 && !fiscalCode.match(fiscalCodeFormat);
  }*/

  checkFormInvalid(){
    if(!(this.form.get("name")?.value && this.form.get("surname")?.value)) {
      return true;
    }
    
    if(this.checkEmailFields()) return true;
      
    return (!this.form.get("terminiChecked")?.value)
  }

}

function forbiddenNameValidator(control: FormControl) {
  let nameRe: RegExp = /\p{Emoji}/u;
  
  const forbidden = nameRe.test(control.value);
  return forbidden ? {forbiddenName: {value: control.value}} : null;
}