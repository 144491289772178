import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserField } from 'src/app/model/event.model';
import { Guest, User } from 'src/app/model/reservation.model';
import { BookingService } from 'src/app/service/booking.service';
import { HomeService } from 'src/app/service/home.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-complete-booking',
  templateUrl: './complete-booking.component.html',
  styleUrls: ['./complete-booking.component.scss'],
})
export class CompleteBookingComponent implements OnInit {

  successTitle: string;
  errorMessage: string;

  event_name: string;
  event_datetime: string;
  address: string;
  user: User = new User();
  number_of_users: string;
  user_guests: Array<Guest>;
  
  private user_fields: Array<UserField> = new Array<UserField>();
  private idReservation: string;

  isSuccess: boolean = false;
  isLoading: boolean = true;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private bookingService: BookingService,
    private homeService: HomeService,
    private storage: StorageService
  ) {}

  ngOnInit() {

    this.successTitle = 'Attenzione! È stato riscontrato un errore';
    this.errorMessage = 'Qualcosa non ha funzionato correttamente in fase di prenotazione, ti invitiamo a provare nuovamente facendo clic sul pulsante "Ritenta".';

    this.router.params.subscribe((params) => {
      if (params) {
        if (
          params.idReservation != '0' &&
          params.idReservation != 'undefined'
        ) {
          this.idReservation = params.idReservation;
          this.successTitle = 'Consulta la tua e-mail per i dettagli della prenotazione';
          this.isSuccess = true;
          this.getReservation();
        } else {
          let message = this.homeService.getErrorMessage();
          if (message) this.errorMessage = message;
          
          this.showPage();
        }
      } else {
        this.showPage();
      }
    });
  }

  private getReservation() {
    let token = this.homeService.getEditTokenObject();
    let storedEvent = this.homeService.getEventObject();
    if (storedEvent) {
      this.user_fields = storedEvent.user_fields;
    }
    
    this.bookingService
      .getReservationbyId(this.idReservation, token)
      .subscribe((res) => {
        if (res) {
          this.event_name = res.event_name;
          this.event_datetime = res.event_datetime;
          this.address = res.address;
          this.user.user_name = res.name;
          this.user.user_last_name = res.surname;
          this.user.user_email = res.email;
          this.user.user_phone = res.phone;
          this.user.user_fiscal_code = res.fiscal_code;
          this.number_of_users = res.number_of_users;
          this.user_guests = res.user_guest;
        }

        this.showPage();
      }
    );
  }

  private showPage() {
    this.storage.clearStorage();
    this.isLoading = false;
  }

  findUserField(reference_name: string) {
    return this.user_fields.find(el => {return el.user_field_reference_name == reference_name});
  }

  get successImage() {
    if (this.isSuccess) {
      return '../../../assets/img/check.png';
    } else {
      return '../../../assets/img/remove.png';
    }
  }

  goToCalendar() {
    this.route.navigate(['/date-picking']);
  }

  goToHome() {
    this.route.navigate(['/home']);
  }

}
