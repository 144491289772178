<div *ngIf="isLoading">
    <div class="intestazione-container">
        <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
    </div>
    <div class="spinner-loading">
        <div>
            <span class="spinner-double-dot-stick"></span>
        </div>
    </div>
</div>

<div *ngIf="!isLoading" class="edit-booking">
    <div class="intestazione-container">
        <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
      </div>
      <div class="d-flex justify-content-center m-5">
          <h1 style="color: green;font-weight: 500;"> Modifica Prenotazione </h1>
      </div>
      <div class="sub-title-container">
          <p>Vuoi modificare la tua prenotazione?</p>
      </div>
      <div class="edit-btn-container">
          <button class="btn-submit" (click)="goTo('edit')">Modifica prenotazione</button>
      </div>
      <div class="sub-title-container">
        <p>Vuoi cancellare la tua prenotazione?</p>
    </div>
    <div class="edit-btn-container">
        <button class="btn-submit" (click)="goTo('cancel')">Cancella prenotazione</button>
    </div>
</div>