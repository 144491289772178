import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from "rxjs/operators";
import { HomeService } from 'src/app/service/home.service';
import { EventService } from 'src/app/service/event.service';
import { Slot } from 'src/app/model/slot.model';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  isLoading: boolean = true;
  isComplete: boolean = false;
  canGoBack: boolean = true;

  selectedDate: Date = new Date();
  selectedSlot: Slot;

  orari_mattino: Slot[] = [];
  orari_pomeriggio: Slot[] = [];
  orari_mattino_disabled: boolean = false;
  orari_pomeriggio_disabled: boolean = false;

  private uuid_event: string;
  private old_number_of_users?: number;
  private old_selected_slot_id?: number;

  minDate: Date;
  maxDate: Date;

  isMattinoExpanded: boolean = false;
  isPomeriggioExpanded: boolean = false;

  constructor(
    private route: Router,
    private eventService: EventService,
    private homeService: HomeService
  ) {}

  ngOnInit() {
    this.loadAvailableDays();
    this.checkBookedReservation();
  }

  private loadAvailableDays() {
    let res = this.homeService.getEventObject();
    if (res) {
      this.uuid_event = res.uuid_event;
      this.getEventAvailableDays(res.min_reservable_date, res.end_date);
    } else {
      this.goBack();
    }
  }

  private getEventAvailableDays(min_reservable_date: string, end_date: string) {
    this.minDate = new Date(min_reservable_date);
    this.maxDate = new Date(end_date);

    this.selectedDate = this.minDate;

    this.handleDateSelection();
  }

  private checkBookedReservation() {
    let res = this.homeService.getEditReservationObject();
    if (res) {
      this.old_number_of_users = res.number_of_users;
      this.old_selected_slot_id = res.id_event_slot;
      this.canGoBack = false;
    }
  }

  goBack() {
    let baseUrl = '/home';
    
    this.route.navigate([baseUrl]);
  }

  handleTimeSelection(slot: any) {
    this.orari_mattino.forEach((el) => {
        el.selected = false;
    });
    this.orari_pomeriggio.forEach((el) => {
        el.selected = false;
    });

    slot.selected = true;
    this.selectedSlot = slot;
    this.isComplete = true;
  }

  private handleDisabled(orari_morning: any, orari_afternoon: any) {
    if (orari_morning.length == 0) {
      this.orari_mattino_disabled = true;
    } else {
      this.isMattinoExpanded = true;
      this.orari_mattino_disabled = false;
    }

    if (orari_afternoon.length == 0) {
      this.orari_pomeriggio_disabled = true;
    } else {
      this.orari_pomeriggio_disabled = false;
    }
  }

  handleDateSelection() {
    this.isMattinoExpanded = false;
    this.isPomeriggioExpanded = false;
    this.orari_mattino_disabled = true;
    this.orari_pomeriggio_disabled = true;
    this.orari_mattino = [];
    this.orari_pomeriggio = [];
    this.isComplete = false;

    this.selectedDate.setHours(12); // Sennò settava 00:00 e poi con la conversione a ISO portava al giorno precedente
    let tmp = this.selectedDate.toISOString().substring(0, 10);

    this.eventService.getEventSlotsByDate(this.uuid_event, tmp).pipe(
      finalize(() =>
        this.handleDisabled(this.orari_mattino, this.orari_pomeriggio)
      )
    ).subscribe(
      res => {
        res.forEach((el: Slot) => {
          el.selected = false;
          if (el.id_event_slot == this.old_selected_slot_id && this.old_number_of_users) el.available_users += this.old_number_of_users;
          el.available = el.available_users != 0;
          el.label = el.available_users == 1 ? `${el.available_users} posto` : `${el.available_users} posti`;

          if(el.start_time < "14:00") {
            this.orari_mattino.push(el);
          } else {
            this.orari_pomeriggio.push(el);
          }
        });

        this.isLoading = false;
      },
      err => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }

  goToSummary() {
    this.selectedSlot.slot_date_time = this.getFormattedDateTime();
    this.homeService.saveSlotObject(this.selectedSlot);
    this.route.navigate(['summary']);
  }

  private getFormattedDateTime(): string {
    if(!this.selectedDate || !this.selectedSlot) return '';

    return `${this.selectedDate.getDate()}/${this.selectedDate.getMonth() + 1}/${this.selectedDate.getFullYear()} - ${this.selectedSlot.start_time}`;
  }
}
