<div *ngIf="isLoading">
  <div class="intestazione-container">
    <img
      class="image-footer"
      src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg"
    />
  </div>
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>
    </div>
  </div>
</div>

<div *ngIf="!isLoading" class="home-landing">
  <div class="intestazione-container">
    <div class="back-btn-container">
      <button class="btn-summary" (click)="goBack()">
        <div class="d-none d-md-block"><mat-icon>chevron_left</mat-icon>indietro</div>
        <div class="d-md-none"><mat-icon>chevron_left</mat-icon></div>
      </button>
    </div>
    <img
      class="image-footer"
      src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg"
    />
  </div>

  <ng-container *ngIf="hasEvents; else no_events">
    <div class="progress-container">
      <mat-progress-bar mode="determinate" value="25"></mat-progress-bar>
    </div>
    <div class="title-container">
      <h2>Scegli l'evento</h2>
    </div>

    <div class="information-container">

      <mat-selection-list
        #events
        [multiple]="true"
        class="information"
      >
        <mat-list-option
          *ngFor="let el of eventsList"
          [value]="el"
        >
          <h3 style="color: #20623b; font-weight: 500;">{{el.event_name}}</h3>
          <div style="margin-bottom: 10px;">{{el.formatted_start_date}}</div>
          <mat-divider></mat-divider>
        </mat-list-option>
      </mat-selection-list>

    </div>

    <div class="information-container" style="margin-top: 50px;" *ngIf="selectedEvent">
      <div class="information">
        <p style="font-weight: bold">Luogo:</p>
        <p>{{ selectedEvent.event_address }}</p>
        <br />
        <p style="font-weight: bold">Descrizione:</p>
        <p style="white-space: pre-wrap;">{{ selectedEvent.event_description }}</p>
      </div>
    </div>
    <div class="submit-btn">
      <button
        [ngClass]="isComplete ? 'btn-submit' : 'btn-disabled'"
        (click)="goToCalendar()"
        [disabled]="!isComplete"
        style="margin-bottom: 20px"
      >
        Procedi
      </button>
    </div>
  </ng-container>

  <ng-template #no_events>
    <div class="title-container" style="margin-top: 30vh;">
      <h2>Nessun evento al momento disponibile</h2>
    </div>
  </ng-template>
</div>
