import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { CompleteBookingComponent } from './pages/complete-booking/complete-booking.component';
import { DeleteBookingComponent } from './pages/delete/delete-booking/delete-booking.component';
import { DeleteCompletedComponent } from './pages/delete/delete-completed/delete-completed.component';
import { EditBookingComponent } from './pages/edit-booking/edit-booking.component';
import { HomeLandingComponent } from './pages/home-landing/home-landing.component';
import { PreLandingComponent } from './pages/pre-landing/pre-landing.component';
import { SummaryComponent } from './pages/summary/summary.component';


const routes: Routes = [
  {
    path: '',
    component: PreLandingComponent
  },
  {
    path: 'home',
    component: HomeLandingComponent
  },
  {
    path: 'home/:id',
    component: HomeLandingComponent
  },
  {
    path: 'date-picking',
    component: CalendarComponent
  },
  {
    path: 'summary',
    component: SummaryComponent
  },
  {
    path: 'complete',
    component: CompleteBookingComponent
  },
  {
    path: 'edit/:id',
    component: EditBookingComponent
  },
  {
    path: 'cancel',
    component: DeleteBookingComponent
  },
  {
    path: 'cancel-complete',
    component: DeleteCompletedComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
