import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EventOverview } from 'src/app/model/event.model';
import { EventService } from 'src/app/service/event.service';
import { HomeService } from 'src/app/service/home.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-home-landing',
  templateUrl: './home-landing.component.html',
  styleUrls: ['./home-landing.component.scss'],
})
export class HomeLandingComponent implements OnInit {

  @ViewChild(MatSelectionList) events: MatSelectionList;

  isLoading: boolean = true;
  isComplete: boolean = false;
  hasEvents: boolean = false;

  eventsList: Array<EventOverview> = new Array<EventOverview>();
  selectedEvent: EventOverview | undefined;

  private sub: Subscription;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private eventService: EventService,
    private homeService: HomeService,
    private storage: StorageService
  ) { }

  ngOnInit() {
    this.storage.clearStorage();

    this.eventService.getEvents().subscribe(
      res => {
        if (res) {
          this.eventsList = res;

          this.router.params.subscribe((params) => {

            if (params.id) {
              let url_event = this.eventsList.find(el => el.uuid_event == params.id);

              if (url_event) {
                this.selectedEvent = url_event;
                this.hasEvents = this.eventsList.length != 0;
                // Il check sulla option viene settato a mano nel ngAfterContentChecked, solo una volta
              } else {
                this.eventService.getEvent(params.id).pipe(finalize(() => { this.hasEvents = this.eventsList.length != 0; })).subscribe(
                  eventRes => {
                    if (eventRes) {
                      this.eventsList.push(eventRes);
                      let event_to_select = this.eventsList.find(el => el.uuid_event == params.id);
                      if (event_to_select) {
                        this.selectedEvent = event_to_select;
                      }
                    }
                  }
                )
              }
            } else {
              //hasEvents deve essere settato per visualizzare i componenti html e deve essere necessariamente processato dopo che la eventsList è stata completamente popolata.
              this.hasEvents = this.eventsList.length != 0;
            }
            this.isLoading = false;
          });
        }
      },
      err => {
        console.error(err);
        this.hasEvents = false;
        this.isLoading = false;
      }
    );
  }


  ngAfterContentChecked() {

    // Questo viene fatto qui e non nell'onInit, perché la lista di eventi non esiste ancora
    // Quindi non si può aggiungere la subscription al selectionChange
    // this.sub == undefined permette di entrare solo una volta
    if (this.events && this.sub == undefined) {
      if (this.selectedEvent) {

        // Questo viene fatto qui, una volta sola, per settare il check in caso di evento pre-compilato
        // Non si può fare nell'onInit perché non esiste ancora la lista prima che scompaia il loader
        this.events.options.forEach(el => {

          if (el.value.uuid_event == this.selectedEvent?.uuid_event) {
            el.toggle();
            this.isComplete = true;
          }
        });
      }

      this.sub = this.events.selectionChange.subscribe((s: MatSelectionListChange) => {
        this.events.deselectAll();
        s.options[0].selected = true;
        this.selectedEvent = s.options[0].value;
        this.isComplete = true;
      });
    }

  }

  goBack() {
    this.route.navigate(['']);
  }

  goToCalendar() {
    if (this.selectedEvent) {
      this.eventService.getEvent(this.selectedEvent.uuid_event).subscribe(
        res => {
          this.homeService.saveEventObject(res);
          this.route.navigate(['/date-picking']);
        },
        err => {
          console.error(err);
        }
      );
    }
  }

}


