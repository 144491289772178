import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Event } from '../model/event.model';
import { ExistingReservation } from '../model/reservation.model';
import { Slot } from '../model/slot.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {

  private errorMessage: string | undefined = undefined;
  
  private eventSubject = new BehaviorSubject<Event | undefined>(undefined);
  private slotSubject = new BehaviorSubject<Slot | undefined>(undefined);
  private editReservationSubject = new BehaviorSubject<ExistingReservation | undefined>(undefined);
  private editTokenSubject = new BehaviorSubject<string | undefined>(undefined);

  constructor(private storage: StorageService) {}

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  getErrorMessage() {
    let tmp = this.errorMessage;
    this.errorMessage = undefined;
    return tmp;
  }

  saveEventObject(object: Event) {
    this.eventSubject.next(object);
    this.storage.addElement(object, 'event');
  }

  getEventObject(): Event | undefined {
    let tmp = this.storage.getElement('event');
    if(tmp) {
      this.eventSubject.next(tmp);
    }

    return this.eventSubject.getValue();
  }

  saveSlotObject(object: Slot) {
    this.slotSubject.next(object);
    this.storage.addElement(object, 'slot');
  }

  getSlotObject(): Slot | undefined {
    let tmp = this.storage.getElement('slot');
    if(tmp) {
      this.slotSubject.next(tmp);
    }

    return this.slotSubject.getValue();
  }

  saveEditReservationObject(object: ExistingReservation) {
    this.editReservationSubject.next(object);
    this.storage.addElement(object, 'old_reservation_id');
  }

  getEditReservationObject(): ExistingReservation | undefined {
    let tmp = this.storage.getElement('old_reservation_id');
    if(tmp) {
      this.editReservationSubject.next(tmp);
    }

    return this.editReservationSubject.getValue();
  }

  saveEditTokenObject(object: string) {
    this.editTokenSubject.next(object);
    this.storage.addElement(object, 'edit_token');
  }

  getEditTokenObject(): string | undefined {
    let tmp = this.storage.getElement('edit_token');
    if(tmp) {
      this.editTokenSubject.next(tmp);
    }

    return this.editTokenSubject.getValue();
  }

}
