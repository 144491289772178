import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeLandingComponent } from "./pages/home-landing/home-landing.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { SummaryComponent } from "./pages/summary/summary.component";
import { CalendarComponent } from "./pages/calendar/calendar.component";
import { PreLandingComponent } from "./pages/pre-landing/pre-landing.component";
import { EditBookingComponent } from "./pages/edit-booking/edit-booking.component";
import { DeleteBookingComponent } from "./pages/delete/delete-booking/delete-booking.component";
import { DeleteCompletedComponent } from "./pages/delete/delete-completed/delete-completed.component";
import { CompleteBookingComponent } from "./pages/complete-booking/complete-booking.component";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from "src/environments/environment";
import { AngularMaterialModule, MyDateAdapter } from "./angular-material.module";

@NgModule({
  declarations: [
    AppComponent,
    HomeLandingComponent,
    HeaderComponent,
    FooterComponent,
    SummaryComponent,
    CalendarComponent,
    PreLandingComponent,
    CompleteBookingComponent,
    EditBookingComponent,
    DeleteBookingComponent,
    DeleteCompletedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
    AngularMaterialModule
  ],
  providers: [
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: "it" },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

