import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class BookingService {

  constructor(private http: HttpClient, private storage: StorageService) {}

  // API

  createReservation(obj: any): Observable<any> {
    let url = environment.backEndUrl + '/reservations';

    return this.http.post(url, obj);
  }

  getReservationbyId(id: String, token?: string): Observable<any> {
    let url = environment.backEndUrl + `/reservations/${id}`;

    let params = new HttpParams();
    params = params.append('token', token ? token : "");
    
    return this.http.get(url, {params: params});
  }

  deleteReservation(id: string): Observable<any> {
    let url = environment.backEndUrl + `/reservations/${id}`;

    return this.http.delete(url);
  }

  editReservation(obj: any, old_reservation_id: string): Observable<any> {
    let url = environment.backEndUrl + `/reservations/${old_reservation_id}`;

    return this.http.put(url, obj);
  }
}
