<div class="delete-complete">
  <div class="intestazione-container">
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
  </div>
  <div class="icon-container">
    <img class="image-success" src="{{successImage}}">
  </div>
  <div class="title-container">
    <h3 style="font-weight: bold;color: green;">L'appuntamento è stato cancellato</h3>
  </div>
  <div class="low-btn-container">
    <button class="btn-back-submit" (click)="restart()" style="margin-bottom: 20px;">Nuovo Appuntamento</button>
    <!--button class="btn-back-submit" (click)="close()" style="margin-bottom: 20px;">Chiudi</button-->
  </div>
</div>
