import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventService {

  constructor(private http: HttpClient) {}

  getEvents(): Observable<any> {
    let url = environment.backEndUrl + '/events';
    return this.http.get(url);
  }

  getEvent(uuid_event: string): Observable<any> {
    let url = environment.backEndUrl + `/events/${uuid_event}`;
    return this.http.get(url);
  }

  getEventSlotsByDate(uuid_event: string, date: string): Observable<any> {
    let url = environment.backEndUrl + `/events/${uuid_event}/slots`;

    let params = new HttpParams();
    params = params.append('date', date.toString());
    
    return this.http.get(url, {params: params});
  }
}
