export class Reservation {
    uuid_event: string;
    id_event_slot: number;
    recaptcha_token: string;
    number_of_users: number;
    users: Array<User>;
    user_guest: Array<Guest>;

    constructor() {
        let newUser = new User();
        this.users = new Array<User>(newUser);
        this.user_guest = new Array<Guest>();
    }
}

export class User {
    user_name: string;
    user_last_name: string;
    user_email: string;
    user_phone: string;
    user_fiscal_code: string;
}

export class Guest {
    name: string;
}

export class ExistingReservation {
    id_reservation: string;
    uuid_event: string;
    id_event_slot: number;
    name: string;
    surname: string;
    email: string;
    phone: string;
    fiscal_code: string;
    number_of_users: number;
    user_guest: Array<Guest> | null;
}