<div *ngIf="isLoading">
  <div class="intestazione-container">
    <img class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
  </div>
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>
    </div>
  </div>
</div>

<div *ngIf="!isLoading" class="complete">
  <div class="intestazione-container">
    <img
      class="image-footer"
      src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg"
    />
  </div>
  <div class="progress-container">
    <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
  </div>
  <div class="title-container">
    <h3 style="font-weight: bold; color: green">Esito Prenotazione</h3>
  </div>
  <div class="icon-container">
    <img class="image-success" src="{{ successImage }}" />
  </div>
  <div class="subtitle-container">
    <span
      class="title-class"
      [ngStyle]="{ color: isSuccess ? '#20623b' : 'red' }"
      >{{ successTitle }}</span
    >
  </div>

  <div *ngIf="!isSuccess" class="sub-title-class">
    <p>
      {{ errorMessage }}
    </p>
  </div>

  <div *ngIf="isSuccess" class="body-riepilogo">
    <mat-list>
      <div style="color: grey">Evento</div>
      <div>{{ event_name }} - {{ address }}</div>
      
      <mat-divider></mat-divider>
      
      <div style="color: grey">Data e Ora</div>
      <div>{{ event_datetime }}</div>
      
      <mat-divider></mat-divider>
      
      <ng-container *ngIf="findUserField('user_name') as user_name_field">
        <div style="color: grey">{{user_name_field.user_field_label}}</div>
        <div>{{ user.user_name }}</div>
        
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngIf="findUserField('user_last_name') as user_last_name_field">
        <div style="color: grey">{{user_last_name_field.user_field_label}}</div>
        <div>{{ user.user_last_name }}</div>
        
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngIf="findUserField('user_phone') as user_phone_field">
        <div style="color: grey">{{user_phone_field.user_field_label}}</div>
        <div>{{ user.user_phone }}</div>
        
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngIf="findUserField('user_fiscal_code') as user_fiscal_code_field">
        <div style="color: grey">{{user_fiscal_code_field.user_field_label}}</div>
        <div>{{ user.user_fiscal_code }}</div>
        
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngIf="findUserField('user_email') as user_email_field">
        <div style="color: grey">{{user_email_field.user_field_label}}</div>
        <div>{{ user.user_email }}</div>
        
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngIf="findUserField('number_of_users') as number_of_users_field">
        <div style="color: grey">{{number_of_users_field.user_field_label}}</div>
        <div>{{ number_of_users }}</div>
        
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngIf="findUserField('user_guest') as user_guest_field">
        <div style="color: grey">{{user_guest_field.user_field_label}}</div>

        <div *ngFor="let guest of user_guests" style="margin-left: 20px;">
          <div>Dati Accompagnatore</div>
          <div style="display: flex; margin-bottom: 10px;">
            <div>{{ guest.name }}</div>
          </div>
        </div>

        <div *ngIf="!user_guests || user_guests.length == 0">Nessun Accompagnatore presente</div>
        
        <mat-divider></mat-divider>
      </ng-container>
    </mat-list>
  </div>

  <div *ngIf="!isSuccess" class="btn-container">
    <button class="btn-submit" (click)="goToCalendar()" style="margin-bottom: 20px;">
      Ritenta
    </button>
  </div>

  <div *ngIf="isSuccess" class="btn-container">
    <button class="btn-submit" (click)="goToHome()" style="margin-bottom: 20px;">
      Nuovo Appuntamento
    </button>
  </div>

</div>
