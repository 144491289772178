import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericConfirmationComponent } from 'src/app/components/dialog/generic-confirmation/generic-confirmation.component';
import { Event } from 'src/app/model/event.model';
import { ExistingReservation } from 'src/app/model/reservation.model';
import { BookingService } from 'src/app/service/booking.service';
import { EventService } from 'src/app/service/event.service';
import { HomeService } from 'src/app/service/home.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-edit-booking',
  templateUrl: './edit-booking.component.html',
  styleUrls: ['./edit-booking.component.scss']
})
export class EditBookingComponent implements OnInit {

  private oldReservation: ExistingReservation;
  private reservationEvent: Event;
  private editToken?: string;
  isLoading: boolean = true;

  constructor(   
     private route: Router,
     private router: ActivatedRoute,
     private bookingService: BookingService,
     private homeService: HomeService,
     private eventService: EventService,
     private storage: StorageService,
     private dialog: MatDialog
     ) { }

  ngOnInit() {
    this.storage.clearStorage();

    this.router.params.subscribe(params => {
      if (params && params.id != "0" && params.id != "undefined") {
        let idReservation = params.id;

        this.router.queryParams.subscribe(queryParams => {
          this.editToken = queryParams.token;

          this.bookingService.getReservationbyId(idReservation, this.editToken).subscribe(
            res => {
              if (res) {
                this.oldReservation = res;
                this.oldReservation.id_reservation = idReservation;
                this.eventService.getEvent(res.uuid_event).subscribe(
                  res => {
                    this.reservationEvent = res;
                    this.isLoading = false;
                  },
                  err => {
                    console.error(err);
                    this.showError();
                  }
                );
              }
            },
            err => {
              console.error(err);
              let message = err.error?.reason;
              this.showError(message);
            }
          );
        });
      } else {
        this.goTo('home');
      }
    });
  }

  goTo(path: string) {
    switch(path) {
      case('edit'): {
        this.homeService.saveEventObject(this.reservationEvent);
        this.homeService.saveEditReservationObject(this.oldReservation);
        if (this.editToken) this.homeService.saveEditTokenObject(this.editToken);
        this.route.navigate(["/date-picking"]);
        break;
      }

      case('cancel'): {
        //this.route.navigate(["/cancel", {id : this.idReservation}]); Rimossa pagina intermedia
        this.bookingService.deleteReservation(this.oldReservation.id_reservation).subscribe(
          res => {
            this.route.navigate(['/cancel-complete']);
          },
          err => {
            console.error(err);
            this.showError();
          }
        );
        break;
      }

      case('home'): {
        this.route.navigate([""]);
        break;
      }
    }
  }

  private showError(message?: string) {
    if (!message) message = "La prenotazione selezionata non è più esistente."

    const dialogRef = this.dialog.open(GenericConfirmationComponent, {
      data: {
        message: message
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('home');
    });
  }

}
