import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pre-landing',
  templateUrl: './pre-landing.component.html',
  styleUrls: ['./pre-landing.component.scss']
})
export class PreLandingComponent implements OnInit {
  loading = true;

  constructor(
    private route: Router
  ) { }

  ngOnInit() {
    this.loading = false;
  }

  goTo(path?: string) {
    let url: string;

    switch(path) {
      case "home":
        url = "/home"
        break;

      default:
        url = "/home";
    }

    this.route.navigate([url]);
  }

}



